import React from "react";

import { Route, Routes } from "react-router";

import LoginForm from "../../components/Authentication/LoginForm";

import ErrorPage from "../Error";

const PublicPage = (props) => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="*" element={<ErrorPage errorCode="404" />} />
      </Routes>
    </React.Fragment>
  );
};

export default PublicPage;
