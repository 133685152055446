import "./App.css";

import { connect } from "react-redux";

import { Routes, Route, useLocation } from "react-router-dom";
import PublicPage from "./pages/Public";

import React, { Suspense, useEffect } from "react";
import ErrorPage from "./pages/Error";

import CenteredPage from "./components/UI/CenteredPage";
import LoadingIcon from "./components/UI/LoadingIcon";
import ScrollToTop from "./components/UI/ScrollToTop";

import AuthMonitor from "./components/Authentication/AuthMonitor";

import ReactGA from "react-ga4";
import Hotjar from "@hotjar/browser";

if (process.env.REACT_APP_GA_ID) {
  console.log("Setting up GA");
  ReactGA.initialize(process.env.REACT_APP_GA_ID);
}
if (process.env.REACT_APP_HOTJAR_ID) {
  Hotjar.init(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_VERSION || 6);
}

function App(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    if (process.env.REACT_APP_HOTJAR_ID) {
      Hotjar.stateChange(window.location.pathname + window.location.search);
    }
  }, [pathname]);

  const Dashboard = React.lazy(() => import("./pages/Dashboard/index.jsx"));

  const Auth = React.lazy(() => import("./pages/Auth"));
  const Admin = React.lazy(() => import("./pages/Admin"));

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="/p/:profileID/dashboard/*"
          element={
            <Suspense
              fallback={
                <CenteredPage>
                  <LoadingIcon />
                </CenteredPage>
              }
            >
              {props.authed && <Dashboard />}
              {!props.authed && <AuthMonitor />}
            </Suspense>
          }
        />

        <Route
          path="/auth/*"
          element={
            <Suspense
              fallback={
                <CenteredPage>
                  <LoadingIcon />
                </CenteredPage>
              }
            >
              <Auth />
            </Suspense>
          }
        />

        <Route
          path="/admin/*"
          element={
            <Suspense
              fallback={
                <CenteredPage>
                  <LoadingIcon />
                </CenteredPage>
              }
            >
              <Admin />
            </Suspense>
          }
        />

        <Route path="/*" element={<PublicPage />} />
        <Route path="*" element={<ErrorPage errorCode="404" />} />
      </Routes>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    authed: state.user.email,
  };
};

export default connect(mapStateToProps)(App);
