import { createTheme } from "@material-ui/core";

import LitcoX from "../components/UI/Icons/LitcoX";

import Litco from "../components/UI/Icons/Litco";
import LitcoCheck from "../components/UI/Icons/LitcoCheck";

const headingFontFamily = ["DM Serif Display", "Arial"].join(",");
const bodyFontFamily = ["Museo Sans", "Arial"].join(",");
const theme = createTheme({
  palette: {
    background: {
      default: "#f2f2f2",
    },
    primary: {
      main: "#ff6c0e",
    },
    secondary: {
      main: "#84dade",
    },
    error: {
      main: "#ad5c96",
    },
    warning: {
      main: "#d66d2c",
    },
    info: {
      main: "#a4d3d8",
    },
    success: {
      main: "#236950",
    },
  },
  typography: {
    fontFamily: bodyFontFamily,
    h1: {
      fontFamily: headingFontFamily,
    },
    h2: {
      fontFamily: headingFontFamily,
    },
    h3: {
      fontFamily: headingFontFamily,
    },
    h4: {
      fontFamily: headingFontFamily,
    },
    h5: {
      fontFamily: headingFontFamily,
    },
    h6: {
      fontFamily: headingFontFamily,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        a: {
          "&:active": {
            color: "#ff5100",
          },
          "&:visited": {
            color: "#201751",
          },
          color: "#201751",
          cursor: "pointer",
        },
      },
    },
    MuiLink: {
      root: {
        color: "#201751",
        "&:visited": {
          color: "#201751",
        },
        "&:active": {
          color: "#ff5100",
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        color: "white",
      },
    },
  },
  props: {
    MuiAlert: {
      iconMapping: {
        error: <LitcoX fontSize="inherit" />,
        success: <LitcoCheck fontSize="inherit" />,
        warning: <LitcoX fontSize="inherit" />,
        info: <Litco fontSize="inherit" />,
      },
    },
  },
});

export default theme;
