export const TOGGLE_LEFT_MENU = "TOGGLE_LEFT_MENU";
export const TOGGLE_RIGHT_DRAWER = "TOGGLE_RIGHT_DRAWER";
export const OPEN_RIGHT_DRAWER = "OPEN_RIGHT_DRAWER";
export const CLOSE_RIGHT_DRAWER = "CLOSE_RIGHT_DRAWER";
export const OPEN_HELP = "OPEN_HELP";
export const CLOSE_HELP = "CLOSE_HELP";

export const SET_INIT_URL = "SET_INIT_URL";

export const SET_USER_CONFIG = "SET_USER_CONFIG";
export const UPDATE_USER_CONFIG = "UPDATE_USER_CONFIG";

export const SET_PROFILE_CONFIG = "SET_PROFILE_CONFIG";
export const UPDATE_PROFILE_CONFIG = "UPDATE_PROFILE_CONFIG";

export const SET_SNACKBAR_ALERT = "SET_SNACKBAR_ALERT";

export const SET_ANNOUNCEMENTS = "SET_ANNOUNCEMENTS";
