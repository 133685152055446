import actionTypes from "../actions/types/";
import constants from "../../constants/help";

const initState = {
  leftMenuOpen: true,
  rightDrawerOpen: false,
  initUrl: null, //used to redirect on initial load, in case of login required

  userConfig: {},
  profileConfig: {},
  snackbarAlert: {
    severity: "info",
    message: "",
  },
  loadHelp: false,
  help: {
    page: constants.DEFAULT,
    params: {},
  },
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.ui.TOGGLE_LEFT_MENU:
      return { ...state, leftMenuOpen: !state.leftMenuOpen };
    case actionTypes.ui.TOGGLE_RIGHT_DRAWER:
      return { ...state, rightDrawerOpen: !state.rightDrawerOpen };
    case actionTypes.ui.OPEN_RIGHT_DRAWER:
      return { ...state, rightDrawerOpen: true };
    case actionTypes.ui.CLOSE_RIGHT_DRAWER:
      return { ...state, rightDrawerOpen: false };
    case actionTypes.ui.SET_INIT_URL:
      return { ...state, initUrl: action.payload.initUrl };
    case actionTypes.ui.SET_PROFILE_CONFIG:
      return { ...state, profileConfig: action.payload.profileConfig };
    case actionTypes.ui.UPDATE_PROFILE_CONFIG:
      return { ...state, profileConfig: { ...state.profileConfig, ...action.payload } };
    case actionTypes.ui.SET_USER_CONFIG:
      return { ...state, userConfig: action.payload.userConfig };
    case actionTypes.ui.UPDATE_USER_CONFIG:
      return { ...state, userConfig: { ...state.userConfig, ...action.payload } };
    case actionTypes.ui.SET_SNACKBAR_ALERT:
      return { ...state, snackbarAlert: { ...action.payload } };
    case actionTypes.ui.SET_ANNOUNCEMENTS:
      return { ...state, announcements: [...action.payload] };
    case actionTypes.ui.OPEN_HELP: {
      return { ...state, rightDrawerOpen: true, loadHelp: true, help: action.payload };
    }
    case actionTypes.ui.CLOSE_HELP: {
      return { ...state, rightDrawerOpen: false, loadHelp: false, help: { page: constants.DEFAULT, params: {} } };
    }
    default:
      return state;
  }
};

export default reducer;
