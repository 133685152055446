import constants from "../../constants";
import actionTypes from "../actions/types/";
const initState = {
  token: null,
  email: null,
  authError: null,
  doingAuth: false,
  requireDeviceAuth: false,
  profiles: {},
  activeProfileID: null,
  activeProfileData: null,
};

const dismissNotification = (notifications, notificationID) => {
  return notifications.map((n) => {
    if (n.id === notificationID) {
      n.dismissed = constants.NOTIFICATION_DISMISSED.YES;
    }
    return n;
  });
};
const reclaimsNotification = (notifications, notificationID) => {
  return notifications.map((n) => {
    if (n.id === notificationID) {
      n.dismissed = constants.NOTIFICATION_DISMISSED.NO;
    }
    return n;
  });
};

const getReceiptData = (receipts, newEntry) => {
  receipts.push(newEntry);
  return receipts;
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.user.SET_AUTH:
      return { ...state, token: action.payload.token, doingAuth: false };
    case actionTypes.user.SET_AUTH_ERROR:
      return { ...state, authError: action.payload.authError, doingAuth: false, token: null };
    case actionTypes.user.DO_LOGIN:
      return { ...state, authError: null, doingAuth: true };
    case actionTypes.user.SET_PROFILES:
      return { ...state, profiles: action.payload.profiles };
    case actionTypes.user.SET_ACTIVE_PROFILE_ID:
      return { ...state, activeProfileID: action.payload.activeProfileID };
    case actionTypes.user.SET_ACTIVE_PROFILE_DATA:
      return { ...state, activeProfileData: action.payload.activeProfileData };
    case actionTypes.user.UPDATE_ACTIVE_PROFILE_DATA:
      const newProfileData = { ...state.activeProfileData, ...action.payload.activeProfileData };
      return { ...state, activeProfileData: newProfileData };
    case actionTypes.user.REQUIRE_DEVICE_AUTH:
      return { ...state, requireDeviceAuth: action.payload.requireDeviceAuth, doingAuth: false };
    case actionTypes.user.DISMISS_NOTIFICATION:
      let newNotifications = dismissNotification(state.activeProfileData.notifications, action.notificationID);
      return { ...state, activeProfileData: { ...state.activeProfileData, notifications: [...newNotifications] } };
    case actionTypes.user.RECLAIM_NOTIFICATION:
      const reclaimedNotifications = reclaimsNotification(state.activeProfileData.notifications, action.notificationID);
      return {
        ...state,
        activeProfileData: { ...state.activeProfileData, notifications: [...reclaimedNotifications] },
      };

    case actionTypes.user.SET_EMAIL:
      return { ...state, email: action.payload.email };
    default:
      return state;
  }
};

export default reducer;
