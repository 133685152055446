import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import reducers from "./reducers";

import watchUser from "./sagas/user";
import watchUI from "./sagas/ui";

const sagaMiddleware = createSagaMiddleware();
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, storeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(watchUser); //setup the watchers
sagaMiddleware.run(watchUI);
export default store;
