import { SvgIcon } from "@material-ui/core";
import React from "react";

const Litco = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 200 200">
      <polygon points="92.73 78.53 92.73 32.3 77.27 32.3 77.27 78.53 31.04 78.53 31.04 93.99 77.27 93.99 92.73 93.99 92.73 93.99 92.73 78.53 92.73 78.53" />
      <polygon points="104.01 78.53 104.01 32.3 119.47 32.3 119.47 78.53 165.71 78.53 165.71 93.99 119.47 93.99 104.01 93.99 104.01 93.99 104.01 78.53 104.01 78.53" />
      <polygon points="92.73 120.73 92.73 166.97 77.27 166.97 77.27 120.73 31.04 120.73 31.04 105.27 77.27 105.27 92.73 105.27 92.73 105.27 92.73 120.73 92.73 120.73" />
      <polygon points="104.01 120.73 104.01 166.97 119.47 166.97 119.47 120.73 165.71 120.73 165.71 105.27 119.47 105.27 104.01 105.27 104.01 105.27 104.01 120.73 104.01 120.73" />
    </SvgIcon>
  );
};

export default Litco;
