import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../../utils/api";
import actionTypes from "../../../store/actions/types";

import { requireAuth } from "../../../store/actions/user";
const AuthMonitor = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const authMonitor = api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response?.status === 401) {
          requireAuth(dispatch, navigate);
        }

        return Promise.reject(error);
      }
    );
    return () => {
      //remove the interceptor

      api.interceptors.response.eject(authMonitor);
    };
  }, []);

  useEffect(() => {
    if (!props.user.token) {
      console.log("Not logged in. Sending to login.");
      if (location.pathname !== "/" && location.pathname !== "/auth/login") {
        props.setInitUrl(location.pathname);
      }
      navigate("/auth/login");
    }
    if (!!props.checkForProfile && (!props.user.activeProfileID || !props.user.activeProfileData)) {
      console.log("Logged in but no profile selected. Going to profie selection.");
      navigate("/auth/profile-selection");
    }
  }, [props.user.token, props.user.activeProfileID, props.user.activeProfileData]);

  return <React.Fragment></React.Fragment>;
};

const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
  setInitUrl: (url) => dispatch({ type: actionTypes.ui.SET_INIT_URL, payload: { initUrl: url } }),
});
export default connect(mapStateToProps, mapDispatchToProps)(AuthMonitor);
