import { combineReducers } from "redux";
import actionTypes from "../actions/types/";
import { OVERRIDE_STATE } from "../actions/types/index";
import user from "./user";
import ui from "./ui";

const appReducer = combineReducers({
  user,
  ui,
});

const reducer = (state, action) => {
  if (action.type === actionTypes.user.UNSET_AUTH) {
    state = undefined;
  } else if (action.type === OVERRIDE_STATE) {
    state = action.payload;
  }

  return appReducer(state, action);
};
export default reducer;
