import { takeLatest, takeEvery, put, call, select } from "redux-saga/effects";
import actionTypes from "../actions/types/index";
import api from "../../utils/api";
import { SET_AUTH_ERROR } from "../actions/types/user";
import { LaptopWindowsSharp } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

export default function* watchUser() {
  yield takeLatest(actionTypes.user.DO_LOGIN, doLogin);
  yield takeLatest(actionTypes.user.REQUIRE_AUTH, requireAuth);
  yield takeEvery(actionTypes.user.DISMISS_NOTIFICATION, dismissNotification);
  yield takeLatest(actionTypes.user.SET_AUTH, loadUserDetails);
  yield takeEvery(actionTypes.user.SET_AUTH_ERROR, clearLocalToken);
}

function* doLogin(action) {
  //make the api call here
  let output;
  try {
    output = yield call(api.post, "/user/session", {
      email: action.payload.email,
      password: action.payload.password,
      deviceID: action.payload.deviceID,
    });
  } catch (err) {
    console.log(err)
    let errorMsg = err.message ? err.message : "Error";
    errorMsg = err.response?.data?.error ? err.response?.data?.error : errorMsg;
    console.log(actionTypes.user.SET_AUTH_ERROR, errorMsg)
    yield put({ type: actionTypes.user.SET_AUTH_ERROR, payload: { authError: errorMsg } });
    return;
  }
  if (output.data?.error) {
    yield put({
      type: actionTypes.user.SET_AUTH_ERROR,
      payload: { authError: output.data.message ? output.data.message : output.data.error },
    });
    return;
  }
  if (output.data.deviceAuthToken) {
    yield put({
      type: actionTypes.user.REQUIRE_DEVICE_AUTH,
      payload: { requireDeviceAuth: output.data.deviceAuthToken },
    });
    return;
  }
  if (output.data.token) {
    yield window.localStorage.setItem("token", output.data.token); //save token for later login
    yield put({
      type: actionTypes.user.SET_AUTH,
      payload: { token: output.data.token, navigate: action.payload.navigate },
    });
    return;
  }

  yield put({ type: SET_AUTH_ERROR, payload: { authError: "Unexpected response" } });
}

function* loadUserDetails(action) {
  //check there is a token - if it is being resset, nothing else to do.
  if (!action.payload.token) {
    return;
  }

  yield (api.defaults.headers.common["Authorization"] = "LL " + action.payload.token);
  let resp;
  try {
    resp = yield call(api.get, "/user/details");
  } catch (error) {
    yield window.localStorage.removeItem("token");
    yield (api.defaults.headers.common["Authorization"] = "");
    if (error.request.status !== 401) {
      yield put({
        type: actionTypes.user.SET_AUTH_ERROR,
        payload: { authError: resp.data.message ? resp.data.message : resp.data.error },
      });
    }
    return;
  }

  if (!resp.data.email || !resp.data.config) {
    yield put({
      type: actionTypes.user.SET_AUTH_ERROR,
      payload: { authError: "Invalid data return when fetching client details." },
    });
    return;
  }

  const userConfig = resp.data.config ? resp.data.config : {};

  yield put({ type: actionTypes.ui.SET_USER_CONFIG, payload: { userConfig: userConfig } });

  yield put({ type: actionTypes.user.SET_EMAIL, payload: { email: resp.data.email } });
  yield action.payload.navigate("/auth/profile-selection");
}

function* requireAuth(action) {
  yield put({ type: actionTypes.user.SET_AUTH_ERROR, payload: { authError: "Session expired. Please login." } });
  yield put({ type: actionTypes.ui.SET_INIT_URL, payload: { initUrl: action.payload.pathname } });
  yield put({ type: actionTypes.user.SET_AUTH, payload: { token: null } });
}

function* dismissNotification(action) {
  const profileID = yield select((state) => state.user.activeProfileID);
  yield call(api.put, `/profile/${profileID}/notification/${action.notificationID}/dismiss`);
}

function* clearLocalToken(action) {
  yield window.localStorage.removeItem("token");
}
