import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, CardContent, Grid, TextField, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import actionTypes from "../../../store/actions/types/index";
import { Alert } from "@material-ui/lab";
import api from "../../../utils/api";

function DeviceRegistrationForm(props) {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  const handleCancel = () => {
    setCode("");
    dispatch({ type: actionTypes.user.REQUIRE_DEVICE_AUTH, payload: { requireDeviceAuth: false } });
  };
  const handleSubmit = () => {
    setProcessing(true);

    api
      .post("/user/register-device", {
        deviceID: window.localStorage.getItem("deviceId"),
        registrationID: props.registrationToken,
        userID: props.userId,
        code: code,
      })
      .then((resp) => {
        if (resp.data.error) {
          setError(resp.data.error);
        }

        if (resp.data.success) {
          setCode("");
          dispatch({ type: actionTypes.user.REQUIRE_DEVICE_AUTH, payload: { requireDeviceAuth: false } });
          props.onComplete();
          return;
        }
        setProcessing(false);
      })
      .catch((err) => {
        setError(err.message);
        setProcessing(false);
      });
  };

  const handleKeyDown = (e) => {
    if (code.length == 6 && e.keyCode == 13) {
      handleSubmit();
    }
  };
  return (
    <CardContent>
      <Grid container style={{ gap: 10 }}>
        <Grid item xs={12} textAlign="center">
          {error && (
            <Alert severity="error" style={{ margin: 1 }}>
              {error}
            </Alert>
          )}
          <Box textAlign="center">
            <Typography variant="h5" style={{ margin: "10px" }}>
              Get Code From Your Email
            </Typography>
            <Typography style={{ margin: "10px" }}>Enter the code sent to your email.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontWeight: "bold" }}>Verification Code *</Typography>
          <TextField
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter code"
            autoComplete="off"
            inputProps={{ maxLength: 6 }}
            required
            disabled={processing}
            variant="outlined"
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs={12} justifyContent="space-between" container>
          <Grid item>
            <Button onClick={handleCancel} variant="contained">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={code.length != 6 || processing}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              style={{ color: "white" }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  );
}

DeviceRegistrationForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default DeviceRegistrationForm;
