export const DO_LOGIN = "DO_LOGIN";
export const SET_AUTH = "SET_AUTH";
export const UNSET_AUTH = "UNSET_AUTH";
export const SET_AUTH_ERROR = "SET_AUTH_ERROR";
export const REQUIRE_AUTH = "REQUIRE_AUTH";
export const REQUIRE_DEVICE_AUTH = "REQUIRE_DEVICE_AUTH";

export const SET_PROFILES = "SET_PROFILES";
export const SET_ACTIVE_PROFILE_ID = "SET_ACTIVE_PROFILE";
export const SET_ACTIVE_PROFILE_DATA = "SET_ACTIVE_PROFILE_DATA";
export const UPDATE_ACTIVE_PROFILE_DATA = "UPDATE_ACTIVE_PROFILE_DATA";

export const SET_EMAIL = "SET_EMAIL";

export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";
export const RECLAIM_NOTIFICATION = "RECLAIM_NOTIFICATION";
