import { takeLatest, put, call, select } from "redux-saga/effects";
import actionTypes from "../actions/types/index";
import api from "../../utils/api";

export default function* watchUI() {
  yield takeLatest(actionTypes.ui.UPDATE_USER_CONFIG, updateUserConfig);
  yield takeLatest(actionTypes.ui.UPDATE_PROFILE_CONFIG, updateProfileConfig);
}

function* updateUserConfig(action) {
  const userConfig = yield select((state) => state.ui.userConfig);
  yield call(api.put, `/user/config`, { config: userConfig });
}

function* updateProfileConfig(action) {
  
  const profileID = yield select((state) => state.user.activeProfileID);
  const profileConfig = yield select((state) => state.ui.profileConfig); //get the latest state
  if (profileID) {
    yield call(api.put, `/profile/${profileID}/config`, { config: profileConfig });
  }
}
