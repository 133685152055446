import { SvgIcon } from "@material-ui/core";
import React from "react";

const LitcoX = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 224.59 225.47">
      <polygon points="110.65 80.73 143.34 48.03 132.41 37.1 99.72 69.8 67.02 37.1 56.09 48.03 88.78 80.73 99.72 91.66 99.72 91.66 110.65 80.73 110.65 80.73" />
      <polygon points="118.62 88.7 151.31 56.01 162.25 66.94 129.56 99.63 162.25 132.32 151.31 143.26 118.62 110.56 107.69 99.63 107.69 99.63 118.62 88.7 118.62 88.7" />
      <polygon points="80.81 110.56 48.12 143.26 37.19 132.32 69.88 99.63 37.19 66.94 48.12 56.01 80.81 88.7 91.74 99.63 91.74 99.63 80.81 110.56 80.81 110.56" />
      <polygon points="88.78 118.54 56.09 151.23 67.02 162.16 99.72 129.47 132.41 162.16 143.34 151.23 110.65 118.54 99.72 107.61 99.72 107.61 88.78 118.54 88.78 118.54" />
    </SvgIcon>
  );
};

export default LitcoX;
