import { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";

// function ScrollToTop({ history }) {
//   const {pathname} = useLocation
//   useEffect(() => {
//     const unlisten = history.listen(() => {
//       window.scrollTo(0, 0);
//     });
//     return () => {
//       unlisten();
//     };
//   }, []);

//   return null;
// }

// export default withRouter(ScrollToTop);

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
