import { Grid, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
  },
}));
const CenteredPage = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container spacing={0} direction="column" justifyContent="center" className={classes.root}>
        <Grid item container justifyContent="center">
          {props.children}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CenteredPage;
