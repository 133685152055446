import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useFormik } from "formik";
import * as yup from "yup";
import actionTypes from "../../../store/actions/types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AccountCircle, Lock, LockSharp, Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import DeviceRegistrationForm from "./DeviceRegistrationForm";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
  },
  margin: {
    margin: theme.spacing(2),
  },
  centerIcon: {
    display: "flex",
    align: "center",
    justify: "center",
    justifyContent: "center",
  },
}));
const LoginForm = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const classes = useStyles();

  const navigate = useNavigate();

  useEffect(() => {
    if (window.localStorage.getItem("token")) {
      props.doAutoLogin(window.localStorage.getItem("token"), navigate);
    }
  }, []);

  const validationSchema = yup.object({
    email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be minimum 8 characters")
      .required("Password is required"),
  });

  const handleDeviceRegistration = () => {
    //programically resubmit the form
    formik.submitForm();
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({ email, password }) => {
      //device ID used for MFA
      let deviceId = window.localStorage.getItem("deviceId");
      if (!deviceId) {
        deviceId = uuidv4() + uuidv4();
        window.localStorage.setItem("deviceId", deviceId);
      }

      props.doLogin(email, password, deviceId, navigate);
    },
  });

  //const AuthError = <Alert severity="error">{props.authError}</Alert>;
  const AuthError = (_) => <Alert severity="error">Error: {props.authError} </Alert>;
  return (
    <React.Fragment>
      <Grid container spacing={0} direction="column" justifyContent="center" className={classes.root}>
        <Grid item>
          <Grid container spacing={0} direction="row" justifyContent="center">
            <Grid item md={4} sm={9} xs={11}>
              <Card raised>
                <Box style={{ backgroundColor: "black" }} mx="auto" p={2} display="flex" justifyContent="center">
                  <img src={process.env.PUBLIC_URL + "/images/Litco-Law-PO-170.png"} alt="Logo" />
                </Box>
                {props.requireDeviceAuth && (
                  <DeviceRegistrationForm
                    userId={formik.values.email}
                    onComplete={handleDeviceRegistration}
                    registrationToken={props.requireDeviceAuth}
                  />
                )}
                {!props.requireDeviceAuth && (
                  <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                      <h2>Login</h2>
                      {props.authError && <AuthError />}

                      <Grid container style={{ margin: 20 }} spacing={2}>
                        <Hidden smDown>
                          <Grid container item md={1} alignItems="center">
                            <AccountCircle />
                          </Grid>
                        </Hidden>
                        <Grid item md={10} xs={11}>
                          <TextField
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            label="Email Address"
                            autoFocus
                            fullWidth
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            disabled={props.doingAuth}
                          ></TextField>
                        </Grid>
                      </Grid>
                      <Grid container style={{ margin: 20 }} spacing={2}>
                        <Hidden smDown>
                          <Grid container item md={1} alignItems="center">
                            <LockSharp />
                          </Grid>
                        </Hidden>
                        <Grid item md={10} xs={11}>
                          <TextField
                            id="password"
                            name="password"
                            type={showPassword ? "test" : "password"}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            label="Password"
                            autoFocus
                            fullWidth
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            disabled={props.doingAuth}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={() => setShowPassword(!showPassword)}
                                    tabIndex={-1}
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          ></TextField>
                          <Box mt={1}>
                            <Link to="/auth/reset-password">
                              <Typography variant="caption">Forgot Password?</Typography>
                            </Link>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container justifyContent="space-between" alignItems="flex-end">
                        <Grid item>
                          <Typography style={{ fontWeight: "light", fontSize: 12 }}>
                            Need an account?&nbsp;
                            <Link to="/auth/register">Register</Link>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={props.doingAuth}
                            style={{ color: "white" }}
                          >
                            {props.doingAuth ? <CircularProgress size={24} /> : "Login"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                )}
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authError: state.user.authError,
    doingAuth: state.user.doingAuth,
    requireDeviceAuth: state.user.requireDeviceAuth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doLogin: (email, password, deviceID, navigate) => {
      dispatch({
        type: actionTypes.user.DO_LOGIN,
        payload: { email: email, password: password, deviceID: deviceID, navigate: navigate },
      });
    },
    doAutoLogin: (token, navigate) => {
      dispatch({ type: actionTypes.user.SET_AUTH, payload: { token: token, navigate: navigate } });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
