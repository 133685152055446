const help = require("./help");
const consts = {
  // accident type should be in case summary
  ACCIDENT_TYPE: {
    MVA: "Motor Vehicle Accident",
    BICYCLE: "Bicycle Accident - MVA",
    PEDESTRIAN: "Pedestrian Accident",
    PERSONAL: "Personal Injury Accident",
    SAF: "Slip & Fall Accident",
    TAF: "Trip & Fall Accident",
    DOG: "Dog Bite Accident",
    SCOOTER: "Scooter Accident",
    BICYCLE_NON_MVA: "Bicycle Accident",
  },
  CASE_TYPE: {
    PERSONAL_INJURY: "PI",
    // INTAKE: "198",
    // WILL: "Will",
    // REAL_ESTATE: "Real Estate Dev",
    // PROBATE: "Estate and Probate dev",
  },
  PI_CASE_STATUS: {
    FILEOPENING: "File Opening",
    TREATING: "Treating",
    RECORDSGATHERING: "Records Gathering",
    REVIEW: "Records Gathering",
    NEGOTIAION: "Negotiation",
    LITIGATION: "Litigation",
    SETTLED: "Settled",
    CLOSED: "Archived",
  },
  // we don't have branch?
  COMPANY_BRANCH: {
    BRITANIA: "1",
    INTERNATIONAL: "2",
    EDMONTON: "3",
    RED_DEER: "4",
    LETHBRIDGE: "5",
  },
  COMPANY_BRANCH_LOCATION: {
    BRITANIA: {
      code: "BRITANNIA",
      title: "Britannia Crossing",
      address: "Suite 200, 5119 Elbow Drive SW",
      city: "Calgary",
      province: "AB",
      postalCode: "T2V 1H2",
      phone: "403-273-8580",
      mapLink: "https://g.page/litco?share",
    },
    INTERNATIONAL: {
      code: "INTERNATIONAL",
      title: "International Avenue",
      address: "Suite 110, 4020 17th Ave SE",
      city: "Calgary",
      province: "AB",
      postalCode: "T2A 0S7",
      phone: "403-273-8580",
      mapLink: "https://goo.gl/maps/usmPtiPiKPsuYzr58",
      apptOnly: true,
    },
    EDMONTON: {
      code: "EDMONTON",
      title: "Edmonton",
      address: "8414 109 Street NW",
      city: "Edmonton",
      province: "AB",
      postalCode: "T6G 1E2",
      phone: "780-540-0557",
      mapLink: "https://goo.gl/maps/GJTGqk8R1bYasJoZA",
    },
    RED_DEER: {
      code: "RED_DEER",
      title: "Red Deer",
      address: "4919 59 Street",
      city: "Red Deer",
      province: "AB",
      postalCode: "T4N 6C9",
      phone: "587-960-4080",
      mapLink: "https://maps.app.goo.gl/S6kEjdojzC7rmF6Q6",
      apptOnly: true,
    },
    LETHBRIDGE: {
      code: "LETHBRIDGE",
      title: "Lethbirdge",
      address: "280 – 876 Heritage Blvd W",
      city: "Lethbridge",
      province: "AB",
      postalCode: "T1K 8G1",
      phone: "403-736-0448",
      mapLink: "https://maps.app.goo.gl/WUVnuo3sRyo7DRpi8",
      apptOnly: true,
    },
  },
  TEAM_MEMBER_TYPE: {
    LAW: "law",
    CCS: "ccs",
    SS: "cc", //solutions
    ACT: "act", //accounting
    LA: "la",
    CM: "cm",
  },
  NOTIFICATION_TYPE: {
    GENERAL_NOTICE: "GN",
    SECURITY_NOTICE: "SN",
    ACTION_REQUEST: "AR",
  },
  NOTIFICATION_DISMISSED: {
    NO: 0,
    YES: 1,
    NOT_ALLOWED: 2,
  },
  NOTIFICATION_PRIORITY: {
    LOW: 0,
    MEDIUM: 1,
    HIGH: 2,
  },
  INFO_REQUEST_DATA_TYPE: {
    DOCUMENTS: 1,
    IMAGES: 2,
    TEXT: 3,
  },
  INFO_REQUEST_STATUS: {
    INCOMPLETE: 0,
    PENDING: 1,
    COMPLETE: 2,
  },
  HELP: help,
  TOKEN_STATUS: {
    TOKEN_STATUS_OK: "0",
    TOKEN_ERROR_EXPIRED: "601",
    TOKEN_ERROR_INVALID: "602",
    TOKEN_ERROR_INVALID_EXPIRED: "603",
    TOKEN_ERROR_MISSING: "604",
    TOKEN_ERROR_ACCOUNT_DISABLED: "605",
    SIGNUP_ERROR_EXPIRED: "606",
    ACCOUNT_ERROR_EXISTS: "607",
    ACCOUNT_ERROR_MISSING: "608",
    REQUEST_ERROR_EXCEEDED: "609",
  },
};

module.exports = Object.freeze(consts);
