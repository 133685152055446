import { SvgIcon } from "@material-ui/core";
import React from "react";

const LitcoCheck = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 224.59 225.47">
      <path d="M56.84,136.24,18.44,94.8,32.29,82l39.5,42.61c16.59-17.08,32.46-35.15,50.73-50.5,16.59-13.93,35.86-25.5,57.2-29.28-41.84,28.93-73.85,67.82-104.81,107.65l-.11.14-1.16,1.53Z" />
    </SvgIcon>
  );
};

export default LitcoCheck;
