import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  root: {
    marginTop: 30,
  },
  title: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  errorMessage: {
    marginTop: 40,
  },
  logo: {
    width: 250,
  },
});
const ErrorPage = (props) => {
  const classes = useStyles();

  const errorCode = props.errorCode || 500;
  let message = "Something went wrong";
  switch (errorCode) {
    case "404":
      message = "The page you're trying to access doesn't exist";
      break;
    default:
      break;
  }

  if (props.errorMessage) {
    message = props.errorMessage;
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.root}>
        <Grid item xs={12}>
          <img src={process.env.PUBLIC_URL + "/images/Litco-Law-300.png"} alt="Logo" className={classes.logo} />
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Grid item xs={12} container>
          <Grid item md={6}>
            <Typography variant="h1" className={classes.title}>
              Uh oh!
            </Typography>
            <Typography>{message}</Typography>
            <Typography className={classes.errorMessage}>
              Error code: <strong>{props.errorCode && props.errorCode}</strong>
            </Typography>
          </Grid>
          <Grid item md={6}>
            <img src={process.env.PUBLIC_URL + "/images/Eye-Glasses.png"} alt="Looking" />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default ErrorPage;
